<template>
  <span
    v-bind="$attrs"
    class="dark:hover:text-gray-100 hover:text-primaryLight-900 textmode-neutral-500 text-md font-medium rounded-lg cursor-pointer mx-px h-10 flex lg:flex items-center px-6"
  >
    <span class="block">
      <slot></slot>
    </span>
  </span>
</template>
