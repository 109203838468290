<script lang="ts" setup>
import { PropType, ref } from 'vue'
import { BigNumberInBase } from '@injectivelabs/utils'
import { MarketType } from '@injectivelabs/sdk-ui-ts'
import {
  MarketCategoryType,
  MarketQuoteType,
  UiMarketAndSummaryWithVolumeInUsd
} from '@/types'
import {
  marketIsPartOfCategory,
  marketIsQuotePair,
  marketIsPartOfType,
  marketIsPartOfSearch
} from '@/app/utils/market'
import { deprecatedMarkets, upcomingMarkets } from '@/app/data/market'
import { vOnClickOutside } from '@vueuse/components'

enum MarketHeaderType {
  Market = 'market',
  Change = 'change',
  Volume = 'volume'
}

const props = defineProps({
  markets: {
    type: Array as PropType<UiMarketAndSummaryWithVolumeInUsd[]>,
    required: true
  }
})

const route = useRoute()
const appStore = useAppStore()
const derivativeStore = useDerivativeStore()

const activeCategory = ref(MarketCategoryType.All)
const activeQuote = ref(MarketQuoteType.All)
const activeType = ref('')
const search = ref('')
const sortBy = ref(MarketHeaderType.Volume)
const ascending = ref(false)

const showList = ref(false)

const recentlyExpiredMarkets = computed(
  () => derivativeStore.recentlyExpiredMarkets
)

const favoriteMarkets = computed(() => appStore.favoriteMarkets)

const filteredMarkets = computed(() => {
  return props.markets.filter(({ market }) => {
    const isPartOfCategory = marketIsPartOfCategory(
      activeCategory.value,
      market
    )
    const isPartOfSearch = marketIsPartOfSearch(search.value, market)
    const isPartOfType = marketIsPartOfType({
      market,
      favoriteMarkets: favoriteMarkets.value,
      activeType: activeType.value as MarketType
    })
    const isQuotePair = marketIsQuotePair(activeQuote.value, market)

    return isPartOfCategory && isPartOfType && isPartOfSearch && isQuotePair
  })
})

const sortedMarkets = computed(() => {
  const upcomingMarketsSlugs = upcomingMarkets.map(({ slug }) => slug)
  const deprecatedMarketsSlugs = deprecatedMarkets.map(({ slug }) => slug)

  if (sortBy.value.trim() === '') {
    return filteredMarkets.value
  }

  const markets = [...filteredMarkets.value].sort(
    (
      m1: UiMarketAndSummaryWithVolumeInUsd,
      m2: UiMarketAndSummaryWithVolumeInUsd
    ) => {
      if (
        upcomingMarketsSlugs.includes(m1.market.slug) ||
        deprecatedMarketsSlugs.includes(m1.market.slug)
      ) {
        return 1
      }

      if (sortBy.value === MarketHeaderType.Market) {
        return m2.market.ticker.localeCompare(m1.market.ticker)
      }

      if (sortBy.value === MarketHeaderType.Change) {
        if (new BigNumberInBase(m2.summary.change).eq(m1.summary.change)) {
          return m1.market.ticker.localeCompare(m2.market.ticker)
        }

        return new BigNumberInBase(m2.summary.change)
          .minus(m1.summary.change)
          .toNumber()
      }

      if (new BigNumberInBase(m2.volumeInUsd).eq(m1.volumeInUsd)) {
        return m1.market.ticker.localeCompare(m2.market.ticker)
      }

      // default: sort by volume
      return m2.volumeInUsd.minus(m1.volumeInUsd).toNumber()
    }
  )

  return ascending.value ? markets.reverse() : markets
})

onMounted(() => {
  prefillFromQueryParams()
})

function handleSort(value: string) {
  if (value !== sortBy.value) {
    sortBy.value = value as MarketHeaderType
  }
}

function handleAscending(value: boolean) {
  ascending.value = value
}

function prefillFromQueryParams() {
  const { query } = route

  const category = (
    typeof query.category === 'string'
      ? query.category.trim().toLowerCase()
      : query.category
  ) as MarketCategoryType

  const quote = (
    typeof query.quote === 'string'
      ? query.quote.trim().toLowerCase()
      : query.quote
  ) as MarketQuoteType

  const type = (
    typeof query.type === 'string'
      ? query.type.trim().toLowerCase()
      : query.type
  ) as MarketType

  if (quote && Object.values(MarketQuoteType).includes(quote)) {
    activeQuote.value = quote
  }

  if (category && Object.values(MarketCategoryType).includes(category)) {
    activeCategory.value = category
  }

  if (type && MarketType.Favorite.toLowerCase() === type) {
    activeType.value = MarketType.Favorite
  }

  if (type && MarketType.Spot.toLowerCase() === type) {
    activeType.value = MarketType.Spot
  }

  if (type && [MarketType.Perpetual.toLowerCase(), 'perp'].includes(type)) {
    activeType.value = MarketType.Perpetual
  }
}

function isShowList() {
  showList.value = !showList.value
}

function closeModal() {
  showList.value = false
}

</script>

<template>
  <div>
    <div>
      <div @click="showList = true" >
        <PartialsMarketsFiltersSearchBarIndex
          v-model:active-category="activeCategory"
          v-model:active-quote="activeQuote"
          v-model:active-type="activeType"
          v-model:search="search"
        />
      </div>

      {{}}

      <div v-if="showList"
          v-on-click-outside="closeModal"
          class="absolute left-0 lg:left-auto scrollbar bg-gray-50 dark:bg-gray-900 lg:mt-3 lg:rounded-2xl boxshadow h-[80vh] lg:h-auto lg:max-h-[420px] overflow-y-auto w-full marketsdropdown 2xl:ml-[50px] 4xl:ml-[100px]"
          >
        <CommonTableBody
          :show-empty="sortedMarkets.length === 0"
          class="overflow-y-auto w-full"
          
        >
          <PartialsMarketsSearchbarRow
            v-for="({ market, summary, volumeInUsd }, index) in sortedMarkets"
            :key="`market-row-${index}`"
            :market="market"
            :summary="summary"
            :volume-in-usd="volumeInUsd"
            @searchbar-row:clicked="closeModal"
          />

          <template #empty>
            <CommonEmptyList
              class="min-h-3xs"
              data-cy="markets-no-data-table"
              :message="
                activeType === MarketType.Favorite
                  ? $t('markets.emptyHeaderFavorites')
                  : $t('markets.emptyHeader')
              "
            >
              <template #icon>
                <BaseIcon name="star-border" class="textmode-neutral-500 w-8 h-8" />
              </template>

              <span
                v-if="activeType === MarketType.Favorite"
                class="mt-2 text-xs textmode-neutral-500"
              >
                {{ $t('markets.emptyDescriptionFavorites') }}
              </span>

              <span v-else class="mt-2 text-xs textmode-neutral-500">
                {{ $t('markets.emptyDescription') }}
              </span>
            </CommonEmptyList>
          </template>
        </CommonTableBody>
      </div>
    </div>
    <div v-if="recentlyExpiredMarkets.length > 0" class="mt-12">
      <h3 class="text-sm tracking-wider leading-6 mb-4">
        {{ $t('markets.expiredRecently') }}
      </h3>

      <PartialsMarketsExpired :markets="recentlyExpiredMarkets" />
    </div>
  </div>
</template>

<style>

@media (min-width: 1024px) {
  .marketsdropdown {
    width: 42%;
}
}
</style>