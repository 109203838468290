<script lang="ts" setup>
import { MarketType } from '@injectivelabs/sdk-ui-ts'
import { DefaultMarket, TradeClickOrigin } from '@/types'
import { amplitudeTradeTracker } from '@/app/providers/amplitude'
import {
  getDefaultPerpetualMarketRouteParams,
  getDefaultSpotMarketRouteParams
} from '@/app/utils/market'
import { IS_MAINNET } from '@/app/utils/constants'

const appStore = useAppStore()
const walletStore = useWalletStore()

const defaultPerpetualMarketRoute = getDefaultPerpetualMarketRouteParams()
const defaultSpotMarketRoute = getDefaultSpotMarketRouteParams()

const tradeDropdownShown = ref(false)
const rewardsDropdownShown = ref(false)

type Theme = 'light' | 'dark'
const LOCAL_STORAGE_THEME_KEY = 'theme'
const darkMode = useState('theme', () => false)
const setTheme = (newTheme: Theme) => {
  localStorage.setItem(LOCAL_STORAGE_THEME_KEY, newTheme)
  darkMode.value = newTheme === 'dark'
}

watch(darkMode, (selected) => {
  setTheme(selected ? 'dark' : 'light')
})

onMounted(() => {
  const isDarkModePreferred = window.matchMedia('(prefers-color-scheme: dark)').matches
  const themeFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as Theme
  if (themeFromLocalStorage) {
    setTheme(themeFromLocalStorage)
  } else {
    setTheme(isDarkModePreferred ? 'dark' : 'light')
  }
})

function handleSpotTradeClickedTrack() {
  amplitudeTradeTracker.navigateToTradePageTrackEvent({
    market: DefaultMarket.Spot,
    marketType: MarketType.Spot,
    origin: TradeClickOrigin.TopMenu
  })
}

function handlePerpetualTradeClickedTrack() {
  amplitudeTradeTracker.navigateToTradePageTrackEvent({
    market: DefaultMarket.Perpetual,
    marketType: MarketType.Perpetual,
    origin: TradeClickOrigin.TopMenu
  })
}
</script>

<template>
  <div>
    <nav class="block flex-1 lg:flex mt-8 lg:mt-0">
      <div
        class="cursor-pointer mx-2 ml-6 mb-6 lg:hidden flex items-center gap-2"
        @click="$router.push({ name: 'index' })"
      >
        <img src="/logo-v3.svg" class="w-auto h-8" alt="Wavely" />
        <span class="textmode-neutral text-xl mt-2">Wavely</span>
      </div>
      <LayoutNavItem :to="{ name: 'index' }" class="block lg:hidden">
        {{ $t('navigation.home') }}
      </LayoutNavItem>

      <LayoutNavItem
        :to="{ name: 'markets' }"
        class="block tracking-wide"
        :class="[{ 'lg:hover:bg-primaryDark-500': darkMode }, { 'lg:hover:bg-primaryLight-300': !darkMode }]"
        data-cy="header-markets-link"
      >
        {{ $t('trade.markets') }}
      </LayoutNavItem>

      <LayoutNavHoverMenu>
        <template #default>
          <LayoutNavItemDummy id="trade-dropdown"
          class="hidden lg:block tracking-wide"
          :class="[{ 'hover:bg-primaryDark-500': darkMode }, { 'hover:bg-primaryLight-300': !darkMode }]"
          >
            {{ $t('navigation.trade') }}
          </LayoutNavItemDummy>
        </template>

        <template #content>
          <div class="p-2" :class="[{ 'primaryDark-500': darkMode }, { 'primaryLight-500': !darkMode }]">
            <NuxtLink
              :to="defaultSpotMarketRoute"
              class="p-4 block group relative z-50 hover:backdrop-brightness-90 rounded-2xl"
              data-cy="header-trade-link"
              @click="handleSpotTradeClickedTrack"
            >
              <p class="font-semibold text-base">
                {{ $t('navigation.spot') }}
              </p>
              <p class="text-sm mt-1">
                {{ $t('navigation.spotDescription') }}
              </p>
            </NuxtLink>

            <NuxtLink
              :to="defaultPerpetualMarketRoute"
              class="p-4 block group relative z-50 hover:backdrop-brightness-90 rounded-2xl"
              data-cy="header-trade-link"
              @click="handlePerpetualTradeClickedTrack"
            >
              <p class="font-semibold text-base">
                {{ $t('navigation.perpetual') }}
              </p>
              <p class="text-sm mt-1">
                {{ $t('navigation.perpetualDescription') }}
              </p>
            </NuxtLink>

            <NuxtLink
              :to="{ name: 'convert' }"
              class="p-4 block group hover:backdrop-brightness-90 relative z-50 rounded-2xl"
              data-cy="header-convert-link"
            >
              <p class="font-semibold text-base">
                {{ $t('navigation.convert') }}
              </p>
              <p class="text-sm mt-1">
                {{ $t('navigation.convertDescription') }}
              </p>
            </NuxtLink>
          </div>
        </template>
      </LayoutNavHoverMenu>

      <LayoutNavMobile />

      <LayoutNavHoverMenu>
        <template #default>
          <LayoutNavItemDummy id="rewards-dropdown"
          class="hidden lg:block"
          :class="[{ 'hover:bg-primaryDark-500': darkMode }, { 'hover:bg-primaryLight-300': !darkMode }]"
          >
            Explore
          </LayoutNavItemDummy>
        </template>

        <template #content>
          <div class="p-2" :class="[{ 'primaryDark-500': darkMode }, { 'primaryLight-500': !darkMode }]">
            <a
              href="https://inj.supply"
              target="_blank"
              class="p-4 block group hover:backdrop-brightness-90 rounded-2xl"
            >
              <p class="font-semibold text-base flex items-center">
                INJ Community Faucet
                <BaseIcon name="external-link" arrow class="w-auto h-3 ml-2" />
              </p>
            </a>
            <NuxtLink
              :to="{ name: 'trade-and-earn' }"
              class="p-4 block group relative z-50 hover:backdrop-brightness-90 rounded-2xl"
            >
              <p class="font-semibold text-base">
                {{ $t('navigation.tradeAndEarn') }}
              </p>
            </NuxtLink>
            <a
              href="https://dmm.injective.network"
              target="_blank"
              class="p-4 block group hover:backdrop-brightness-90 rounded-2xl hidden"
            >
              <p class="font-semibold text-base flex items-center">
                <span>{{ $t('navigation.dmmProgram') }}</span>
                <BaseIcon name="external-link" arrow class="w-auto h-3 ml-2" />
              </p>
            </a>
            <a
              href="https://hub.injective.network/staking"
              target="_blank"
              class="p-4 block group hover:backdrop-brightness-90 rounded-2xl"
            >
              <p class="font-semibold text-base flex items-center">
                Staking
                <BaseIcon name="external-link" arrow class="w-auto h-3 ml-2" />
              </p>
            </a>
            <a
              href="https://hub.injective.network/governance"
              target="_blank"
              class="p-4 block group hover:backdrop-brightness-90 rounded-2xl"
            >
              <p class="font-semibold text-base flex items-center">
                Governance
                <BaseIcon name="external-link" arrow class="w-auto h-3 ml-2" />
              </p>
            </a>
            <a
              href="https://gov.injective.network"
              target="_blank"
              class="p-4 block group hover:backdrop-brightness-90 rounded-2xl"
            >
              <p class="font-semibold text-base flex items-center">
                Governance Forum
                <BaseIcon name="external-link" arrow class="w-auto h-3 ml-2" />
              </p>
            </a>
            <a
              href="https://hub.injective.network/auction"
              target="_blank"
              class="p-4 block group hover:backdrop-brightness-90 rounded-2xl"
            >
              <p class="font-semibold text-base flex items-center">
                Auction
                <BaseIcon name="external-link" arrow class="w-auto h-3 ml-2" />
              </p>
            </a>
          </div>
        </template>
      </LayoutNavHoverMenu>

      <!-- <LayoutNavItem
        class="block"
        data-cy="nav-leaderboard-link"
        :to="{ name: 'leaderboard' }"
      >
        {{ $t('navigation.leaderboard') }}
      </LayoutNavItem> -->

      <LayoutNavItem
        v-if="walletStore.isUserWalletConnected"
        class="block lg:hidden"
        data-cy="header-account-link"
        :to="{ name: 'account', query: { view: 'balances' } }"
      >
        {{ $t('navigation.account') }}
      </LayoutNavItem>

      <LayoutNavItem
        v-if="walletStore.isUserWalletConnected"
        class="block lg:hidden"
        data-cy="nav-activity-link"
        :to="{ name: 'activity' }"
      >
        {{ $t('navigation.activity') }}
      </LayoutNavItem>
    </nav>
  </div>
</template>
