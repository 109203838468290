<script lang="ts" setup>
import { Status, StatusType } from '@injectivelabs/utils'
import { GEO_IP_RESTRICTIONS_ENABLED } from '@/app/utils/constants'
import { amplitudeGenericTracker } from '@/app/providers/amplitude'
import {
  Modal,
  BusEvents,
  AmplitudeEvent,
  WalletModalType,
  WalletConnectStatus
} from '@/types'

const modalStore = useModalStore()
const walletStore = useWalletStore()

type Theme = 'light' | 'dark'
const LOCAL_STORAGE_THEME_KEY = 'theme'
const darkMode = useState('theme', () => false)
const setTheme = (newTheme: Theme) => {
  localStorage.setItem(LOCAL_STORAGE_THEME_KEY, newTheme)
  darkMode.value = newTheme === 'dark'
}

const status: Status = reactive(new Status(StatusType.Loading))
const walletModalType = ref<WalletModalType>(WalletModalType.All)

const isModalOpen = computed<boolean>(
  () => modalStore.modals[Modal.Connect] && !walletStore.isUserWalletConnected
)

const isLoading = computed<boolean>(
  () => walletStore.walletConnectStatus === WalletConnectStatus.connecting
)

onMounted(() => {
  useEventBus<string>(BusEvents.ShowLedgerConnect).on(handleLedgerConnect)

  Promise.all([
    walletStore.isMetamaskInstalled(),
    walletStore.isTrustWalletInstalled()
  ]).finally(() => status.setIdle())
})

function handleLedgerConnect() {
  walletModalType.value = WalletModalType.Ledger

  modalStore.openModal(Modal.Connect)
}

function handleWalletConnect() {
  amplitudeGenericTracker.trackEvent(AmplitudeEvent.ConnectClicked)

  if (GEO_IP_RESTRICTIONS_ENABLED) {
    modalStore.openModal(Modal.Terms)
  } else {
    modalStore.openModal(Modal.Connect)
  }
}

function handleModalClose() {
  modalStore.closeModal(Modal.Connect)
}

function updateWalletModalType(type: WalletModalType) {
  walletModalType.value = type
}

watch(
  () => walletStore.walletConnectStatus,
  (newWalletConnectStatus) => {
    if (newWalletConnectStatus === WalletConnectStatus.connected) {
      modalStore.closeModal(Modal.Connect)
      modalStore.openPersistedModalIfExist()
    }
  }
)

watch(isModalOpen, (newShowModalState) => {
  if (!newShowModalState) {
    handleModalClose()
    walletModalType.value = WalletModalType.All
  }
})
</script>

<template>
  <div>
  <LayoutWalletDetails v-if="walletStore.isUserWalletConnected" />

  <div
    v-else
    class="whitespace-nowrap button text-white flex h-full justify-center items-center"
    :class="[{ 'button-dark': darkMode }, { 'button-light': !darkMode }]"
    @click="handleWalletConnect"
  >
    <span class="hidden lg:flex">
      {{ $t('connect.connectWallet') }}
    </span>
    <span class="lg:hidden">
      Connect
    </span> 
  </div>

  <AppModal
    :is-open="isModalOpen"
    :is-loading="isLoading"
    :ignore="['.v-popper__popper']"
    md
    @modal:closed="handleModalClose"
    class="fade"
  >
    <template #title>
      <h3 v-if="walletModalType === WalletModalType.Trezor">
        {{ $t('connect.connectUsingTrezor') }}
      </h3>
      <h3 v-else-if="walletModalType === WalletModalType.Ledger">
        {{ $t('connect.connectUsingLedger') }}
      </h3>
      <h3 v-else>
        {{ $t('connect.connectToWallet') }}
      </h3>
    </template>

    <LayoutWalletLedger v-if="walletModalType === WalletModalType.Ledger" />
    <LayoutWalletTrezor
      v-else-if="walletModalType === WalletModalType.Trezor"
    />
    <ul
      v-else
      class="overflow-auto"
    >
      <LayoutWalletConnectWalletMetamask class="wallet-bg" />
      <LayoutWalletConnectWalletKeplr class="wallet-bg" />
      <LayoutWalletConnectWalletCosmostation class="wallet-bg" />
      <LayoutWalletConnectWalletLeap class="wallet-bg" />
      <LayoutWalletConnectWalletTorus class="wallet-bg" />
      <LayoutWalletConnectWalletTrustWallet class="wallet-bg" />
      <LayoutWalletConnectWalletLedger @click="updateWalletModalType" class="wallet-bg" />
      <LayoutWalletConnectWalletTrezor @click="updateWalletModalType" class="wallet-bg" />
    </ul>
  </AppModal>
  <ModalsTerms />
  </div>
</template>

<style>
.wallet-bg {
  @apply rounded-2xl bg-[#d2d9e58f] dark:bg-[#d2d9e50f] m-2 hover:dark:bg-[#d2d9e525] hover:bg-[#b6c0d287];
}
</style>
