<script lang="ts" setup>
import { PropType } from 'vue'
import { MarketType } from '@injectivelabs/sdk-ui-ts'
import { MarketCategoryType, MarketQuoteType } from '@/types'

const route = useRoute()
const router = useRouter()

const props = defineProps({
  search: {
    type: String,
    required: true
  },

  activeType: {
    type: String,
    required: true
  },

  activeQuote: {
    type: String as PropType<MarketQuoteType>,
    required: true
  },

  activeCategory: {
    type: String as PropType<MarketCategoryType>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'update:activeCategory', state: MarketCategoryType): void
  (e: 'update:search', state: string): void
  (e: 'update:activeQuote', state: MarketQuoteType): void
  (e: 'update:activeType', state: string): void
}>()

const FilterList = {
  [MarketType.Favorite]: MarketType.Favorite,
  All: '',
  [MarketType.Spot]: MarketType.Spot,
  [MarketType.Derivative]: MarketType.Derivative
}
const marketCategoryTypes = Object.entries(MarketCategoryType).map(
  ([key, value]) => ({
    key: `market-category-type-${value}`,
    label: key,
    type: MarketCategoryType[key as keyof typeof MarketCategoryType]
  })
)
const quoteOptions = Object.entries(MarketQuoteType).map(([key, value]) => ({
  display: value,
  value: key
}))

const activeQuoteValue = computed({
  get: (): MarketQuoteType => props.activeQuote,
  set: (value: MarketQuoteType) => {
    emit('update:activeQuote', value)
  }
})

const activeFilterType = computed({
  get: (): string => props.activeType,
  set: (type: string) => {
    emit('update:activeType', type)

    if (type === props.activeType) {
      return
    }

    if (!type || type === '') {
      clearRouteQueryParam('type')
    } else {
      fillRouteQueryParams({ type: type.toLowerCase() })
    }
  }
})

function handleCategoryChange(category: string) {
  emit('update:activeCategory', category as MarketCategoryType)

  if (category === props.activeCategory) {
    return
  }

  if (!category || category === MarketCategoryType.All) {
    clearRouteQueryParam('category')
  } else {
    fillRouteQueryParams({ category })
  }
}

function handleSearchedEvent(search: string) {
  emit('update:search', search)
}

function handleQuoteChange(quote: string) {
  if (quote === props.activeQuote) {
    return
  }

  if (!quote || quote === MarketQuoteType.All) {
    clearRouteQueryParam('quote')
  } else {
    fillRouteQueryParams({ quote })
  }
}

function clearRouteQueryParam(key: string) {
  const { query } = route
  const queryClone = { ...query }

  delete queryClone[key]

  router.replace({ query: queryClone })
}

function fillRouteQueryParams(params: Record<string, string>) {
  router.replace({
    path: route.path,
    query: {
      ...route.query,
      ...params
    }
  })
}
</script>

<template>
  <div class="2xl:ml-[50px] 2xl:mr-[75px] 4xl:ml-[100px] 4xl:mr-[300px] boxshadow rounded-3xl textmode-neutral my-2">
      <div class="hidden lg:flex">
        <AppSearch
          name="search"
          class="w-full h-12 px-4"
          data-cy="markets-search-input"
          transparentBg
          :placeholder="$t('trade.search_markets')"
          show-prefix
          :model-value="search"
          @update:modelValue="handleSearchedEvent"
        />
      </div>
      <div class="lg:hidden">
        <AppSearch
          name="search"
          class="w-full h-12 px-4"
          data-cy="markets-search-input"
          transparentBg
          show-prefix
          :model-value="search"
          @update:modelValue="handleSearchedEvent"
        />
      </div>
  </div>
</template>
